import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectGrid from '../components/ProjectGrid'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/apartamento-213-n/header-right-banner.jpg'
import projecPhoto1 from '../images/projects/apartamento-213-n/apt-template-1.jpg'
import projecPhoto2 from '../images/projects/apartamento-213-n/apt-template-2.jpg'
import projecPhoto3 from '../images/projects/apartamento-213-n/apt-template-3.jpg'
import projecPhoto4 from '../images/projects/apartamento-213-n/apt-template-4.jpg'
import projecPhoto5 from '../images/projects/apartamento-213-n/apt-template-5.jpg'
import projecPhoto6 from '../images/projects/apartamento-213-n/apt-template-6.jpg'
import projecPhoto7 from '../images/projects/apartamento-213-n/apt-template-7.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Apartamento',
  subTitle: '213n',
  typeService: 'PROJETO + OBRA',
  duration: '09 meses',
  deliverDate: '2018',
  local: 'Brasília',
  color: '#12b2b2',
  description: (
    <Fragment>
      <p>
        Nesse projeto <b>reformulamos completamente</b> o apartamento de dois
        quartos de uma casal que queria curtir melhor seu espaço, depois que os
        filhos saíram de casa. Apesar dos clientes terem pedido soluções
        neutras, eles toparam um pouquinho de ousadia com algumas pitadas de cor
        no projeto.
      </p>
      <p>
        Nesse caso, também executamos a obra em sistema de{' '}
        <b>Administração Completa</b>, ou seja, os clientes entregam a chaves e
        recebem o apartamento pronto pra mudança.
      </p>
    </Fragment>
  ),
  photosFirstRow: [
    { id: 'gap1', gap: true, className: 'dimensionGap' },
    { id: '01', image: projecPhoto1, className: 'dimensionOption1' },
    { id: '02', image: projecPhoto4, className: 'dimensionOption2' },
    { id: '03', image: projecPhoto3, className: 'dimensionOption3' },
    { id: '04', image: projecPhoto2, className: 'dimensionOption4' }
  ],
  photosSecondRow: [
    { id: '01', image: projecPhoto5, className: 'dimensionOption5' },
    { id: '02', image: projecPhoto6, className: 'dimensionOption5' },
    { id: '03', image: projecPhoto7, className: 'dimensionOption6' },
    { id: 'gap1', gap: true, className: 'dimensionGap' }
  ],
  nextProject: 'apartamento-306-sw'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Apartamento 213 N"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectGrid
      photosFirstRow={dynamicInfos.photosFirstRow}
      photosSecondRow={dynamicInfos.photosSecondRow}
    />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
